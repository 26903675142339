<template>
  <v-app>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
    <v-container>
      <v-snackbar v-model="snackbar" :timeout="5000">
        <v-container bg fill-height grid-list-md text-xs-center>
          <v-layout row wrap align-center>
            <v-flex>
              <h4>
                <v-icon left dark>mdi-alert-decagram</v-icon>
                {{ feedback }}
              </h4>
            </v-flex>
          </v-layout>
        </v-container>
        <v-btn color="white" text @click="snackbar = false">ปิด</v-btn>
      </v-snackbar>

      <v-stepper v-model="e1" alt-labels>
        <v-stepper-items>
          <v-stepper-content step="1">
            <h4 class="ml-8"></h4>
            <v-autocomplete label="เพิ่มสินค้า" :no-data-text="NoDataText" :items="autoCompletesItem" v-model="autoComplete" :search-input.sync="searchItem" auto-select-first clearable outlined density="comfortable" @change="selectAutoComplete()"></v-autocomplete>

            <v-row no-gutters>
              <v-col cols="6" sm="9">
                <v-text-field label="จำนวน" :rules="quatityRules" type="number" v-model="selectedQuatity" outlined rounded dense></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="text-right">
                <v-btn @click="addItem" class="mx-2" large dark color="indigo"> <v-icon dark left>mdi-cart-arrow-down</v-icon>เพิ่มสินค้า </v-btn>
              </v-col>
            </v-row>

            <v-row class="my-1 py-0">
              <v-col cols="12" class="text-center py-0"> จำนวนรายการ : {{ orders.length }} </v-col>
            </v-row>

            <v-simple-table height="500" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left px-1">รหัสสินค้า</th>
                    <th class="text-left px-1">ชื่อสินค้า</th>
                    <th class="text-left px-1">จำนวน</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orders" :key="order.id">
                    <td class="mx-0 px-0">{{ order.id }}</td>
                    <td class="mx-0 px-0">{{ order.name | truncate(35, "...") }}</td>
                    <td class="mx-0 px-0" style="width: 80px">
                      <v-text-field :full-width="false" class="my-1 mx-0 px-0" :rules="quatityRules" type="number" v-model="order.qty" outlined dense hide-details></v-text-field>
                    </td>
                    <td class="mx-0 px-0" style="width: 30px">
                      <v-btn class="mx-2" fab dark x-small color="red" @click.prevent="deleteItem(order.id)">
                        <v-icon dark>mdi-trash-can</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-row class="my-6" no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn @click="toConfirmStep" class="mx-2 my-2" large dark color="indigo"> <v-icon dark left>mdi-check-circle</v-icon>ดำเนินการต่อ </v-btn>
                <v-btn @click="cancelOrders" class="mx-2 my-2" large dark color="red"> <v-icon dark left>mdi-close-circle</v-icon>ยกเลิก </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="mt-3">
              <v-text-field label="ชื่อร้าน" type="text" v-model="customer_name" outlined dense></v-text-field>
            </div>

            <v-simple-table height="300" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">รหัสสินค้า</th>
                    <th class="text-left">ชื่อสินค้า</th>
                    <th class="text-left">จำนวน</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orders" :key="order.id">
                    <td class="mx-2 px-0">{{ order.id }}</td>
                    <td class="mx-2 px-0">{{ order.name }}</td>
                    <td class="mx-2 px-0">{{ order.qty }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="my-6" no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn @click="confrimOrders" class="mx-2 my-2" large dark color="success"> <v-icon dark left>mdi-check-circle</v-icon>ยืนยัน </v-btn>
                <v-btn @click="e1 = 1" class="mx-2 my-2" large dark color="secondary">ย้อนกลับ</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">เพิ่มสินค้า</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">ยืนยัน</v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import item_list from "@/assets/items.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Home",
  components: { Loading },
  created() {
    item_list.forEach(item => {
      this.itemList.push({
        text: item.display,
        value: item.id,
      });
      this.item_name[item.id] = item.name;
    });
    this.customer_name = this.$route.params.customer_name || "";
    if (this.$route.params.orders) {
      this.$route.params.orders.forEach(order => {
        this.orders.push(order);
      });
    }
  },
  watch: {
    searchItem(val) {
      this.NoDataText = "กรุณาใส่ข้อมูลอย่างน้อย 2 ตัวอักษร";
      if (val === null || val.length < 2) {
        this.autoCompletesItem = [];
        return;
      }
      val && val !== this.autoComplete && this.querySelections(val);
      if (this.autoCompletesItem.length == 0) {
        if (val) {
          this.NoDataText = "ไม่มีข้อมูลสินค้า...";
        }
      }
    },
  },
  methods: {
    querySelections(v) {
      this.autoCompletesItem = this.itemList.filter(e => {
        return (e.text || "").toLowerCase().includes((v || "").toLowerCase());
      });
    },
    selectAutoComplete() {
      if (this.searchItem === "" || this.autoComplete === null) {
        return;
      }
    },
    deleteItem(order_id) {
      this.orders = this.orders.filter(order => order.id != order_id);
    },
    toConfirmStep() {
      if (this.orders.length == 0) {
        this.snackbar = true;
        this.feedback = "กรุณาเพิ่มสินค้า";
        return;
      }

      this.e1 = 2;
    },
    confrimOrders() {
      this.isLoading = true;
      if (this.orders.length == 0) {
        this.snackbar = true;
        this.feedback = "กรุณาเพิ่มสินค้า";
        this.isLoading = false;
        return;
      }
      if (!this.customer_name || this.customer_name == "") {
        this.snackbar = true;
        this.feedback = "กรุณากรอกชื่อร้าน";
        this.isLoading = false;
        return;
      }

      var data = {
        customer_name: this.customer_name,
        orders: this.orders,
      };

      const option = {
        url: process.env.VUE_APP_API_URL + "/order",
        method: "POST",
        data: data,
      };

      axios(option)
        .then(res => {
          this.isLoading = false;
          this.$router.push({
            name: "Order",
            params: {
              id: res.data.data,
            },
          });
        })
        .catch(err => {
          console.error(err.response);
          this.snackbar = true;
          this.feedback = err.response;
          this.isLoading = false;
        });
    },
    cancelOrders() {
      this.orders = [];
    },
    addItem() {
      this.snackbar = false;
      this.feedback = null;
      const isFound = this.orders.some(el => el.id === this.autoComplete);

      if (!this.autoComplete || this.autoComplete == "") {
        this.feedback = "กรุณาเลือกสินค้าที่ต้องการ";
        this.snackbar = true;
        return;
      }

      if (!isFound) {
        var item = {
          id: this.autoComplete,
          qty: this.selectedQuatity,
          name: this.item_name[this.autoComplete],
        };

        this.orders.push(item);
        this.autoComplete = "";
        this.selectedQuatity = 10;
      } else {
        this.snackbar = true;
        this.feedback = "คุณเพิ่มสินค้านี้ไปแล้ว";
      }
    },
  },
  data() {
    return {
      NoDataText: "กรุณาใส่ข้อมูลอย่างน้อย 2 ตัวอักษร",
      quatityRules: [value => !!value || "กรุณาระบุจำนวน.", value => (value && value > 0) || "กรุณาระบุตัวเลขที่มากกว่า 0"],
      e1: 1,
      customer_name: null,
      snackbar: false,
      feedback: null,
      selectedQuatity: 10,
      orders: [],
      isLoading: false,
      autoComplete: "",
      searchItem: "",
      itemList: [],
      item_name: {},
      autoCompletesItem: [],
    };
  },
  metaInfo() {
    return {
      title: "สั่งสินค้า",
    };
  },
};
</script>

<style scoped>
table th {
  font-size: 0.6em;
}

table td {
  font-size: 0.6em;
}

.v-autocomplete {
  font-size: 0.6em;
}

td .v-text-field {
  max-width: 75px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fa8072;
}
</style>
