<template>
  <v-app>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :opacity="1"></loading>
    <v-container>
      <v-snackbar v-model="snackbar" :timeout="3000">
        <v-container bg fill-height grid-list-md text-xs-center>
          <v-layout row wrap align-center>
            <v-flex>
              <h4><v-icon left dark>mdi-check-circle</v-icon>คัดลอกลิงค์เรียบร้อย</h4>
            </v-flex>
          </v-layout>
        </v-container>
        <v-btn color="white" text @click="snackbar = false">ปิด</v-btn>
      </v-snackbar>
      <v-row>
        <v-col cols="6" class="text-center">
          <v-btn @click="goHome" large dark color="primary"> <v-icon dark left>mdi-home</v-icon>กลับสู่หน้าหลัก </v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn @click="orderAgain" large dark color="secondary"> <v-icon dark left>mdi-repeat</v-icon>สั่งอีกครั้ง </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mx-5">
          <h3>หมายเลขออเดอร์ : {{ order_id }}</h3>
          <h4>ชื่อร้าน : {{ order.customer_name }}</h4>
          <p class="py-0 my-0">วันที่สร้าง : {{ order.timestring }}</p>
          <p class="py-0 my-0">จำนวนรายการ : {{ order.orders.length || 0 }}</p>
        </v-col>
      </v-row>

      <v-simple-table height="500" v-if="order.customer_name">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ลำดับ</th>
              <th class="text-left">รหัสสินค้า</th>
              <th class="text-left">ชื่อสินค้า</th>
              <th class="text-left">จำนวน</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, idx) in order.orders" :key="order.id">
              <td>{{ idx + 1 }}</td>
              <td>{{ order.id }}</td>
              <td>{{ order.name }}</td>
              <td>{{ order.qty }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card class="mx-auto" outlined>
        <v-card-text>
          <div>ลิงค์ออเดอร์</div>
          <p class="headline text--primary">
            <a :href="order.link">{{ order.link }}</a>
          </p>

          <div class="text--primary">กรุณาคัดลอกลิงค์นี้และแจ้งให้ทางเราทราบ</div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="copyLink" large dark color="success"> <v-icon dark left>mdi-clipboard-multiple-outline</v-icon>คัดลอกลิงค์ </v-btn>
          <v-btn @click="saveFile" large dark color="secondary"> <v-icon dark left>mdi-download</v-icon>ดาว์นโหลด </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FileSaver from "file-saver";
import axios from "axios";
import moment from "moment";
moment.locale("th");

export default {
  name: "Order",
  components: { Loading },
  methods: {
    orderAgain() {
      this.$router.push({
        name: "Home",
        params: {
          customer_name: this.order.customer_name,
          orders: this.order.orders,
        },
      });
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    copyLink() {
      this.snackbar = true;
      this.$copyText(this.order.link);
    },
    saveFile() {
      var text = "";
      text += "ชื่อร้าน: " + this.order.customer_name + "\nวันที่สร้าง: " + this.order.timestring + "\nหมายเลขออเดอร์: " + this.order_id + "\n";
      this.order.orders.forEach(order => {
        text += order.id + "," + order.name + "," + order.qty + "\n";
      });
      var blob = new Blob([text], {
        type: "text/plain;charset=utf-8",
      });
      FileSaver.saveAs(blob, "order.txt");
    },
  },
  data() {
    return {
      isLoading: false,
      snackbar: false,
      order_id: this.$route.params.id,
      order: {
        customer_name: null,
        orders: [],
        timestamp: null,
        link: null,
        timestring: null,
      },
    };
  },
  created() {
    this.isLoading = true;
    axios
      .get(process.env.VUE_APP_API_URL + `/order/${this.$route.params.id}`)
      .then(res => {
        this.isLoading = false;
        var data = res.data;
        this.order["customer_name"] = data.customer_name;
        this.order["orders"] = data.orders;
        this.order["timestamp"] = data.timestamp;
        this.order["timestring"] = moment(data.timestamp).format("lll");
        this.order["link"] = data.link;
      })
      .catch(err => {
        console.log("Error getting document", err);
        this.isLoading = false;
      });
  },
  metaInfo() {
    return {
      title: "ออเดอร์ของฉัน",
    };
  },
};
</script>

<style scoped>
h4 {
  font-size: 0.8em;
}
p {
  font-size: 0.7em;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fa8072;
}
</style>
