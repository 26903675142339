<template>
  <v-app>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="searchWord"
              prepend-icon="mdi-magnify"
              filled
              clear-icon="mdi-close-circle"
              clearable
              label="ค้นหา"
              type="text"
              @click:clear="clearSearch"
              @keydown="resetPage"
              @change="resetPage"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="center">
          <v-pagination
            v-model="currentPage"
            :length="pageCount"
            :page="currentPage"
            :total-visible="10"
          ></v-pagination>
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ชื่อร้าน</th>
                <th class="text-left">วันที่</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in filteredOrder" :key="order.id">
                <td>{{ order.customer_name }}</td>
                <td>{{ order.timestring }}</td>
                <td>
                  <v-btn class="mx-2" fab x-small color="white" :href="order.link">
                    <v-icon dark>mdi-link-variant</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import firebase from "@/firebase/init";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
moment.locale("th");

export default {
  name: "Admin",
  components: { Loading },
  data: () => {
    return {
      orders: [],
      searchWord: "",
      isLoading: false,
      currentPage: 1,
      pageCount: 0,
      itemPerPage: 10
    };
  },
  methods: {
    resetPage() {
      if (this.pageCount < this.currentPage) this.currentPage = 1;
    },
    clearSearch() {
      this.currentPage = 1;
      this.searchWord = null;
    }
  },
  computed: {
    searchedOrder() {
      if (!this.searchWord || this.searchWord == "") {
        return this.orders;
      } else {
        return this.orders.filter(order =>
          order.customer_name.match(this.searchWord)
        );
      }
    },
    filteredOrder() {
      var startPost = (this.currentPage - 1) * this.itemPerPage;
      this.pageCount = Math.ceil(this.searchedOrder.length / this.itemPerPage);
      var current_page_posts = this.searchedOrder.slice(
        startPost,
        startPost + this.itemPerPage
      );
      return current_page_posts;
    }
  },
  beforeMount() {
    this.isLoading = true;
    let user = firebase.auth().currentUser;
    user.getIdToken(true).then(idToken => {
      axios
        .get(
          process.env.VUE_APP_API_URL+ "/orders?limit=150",
          { headers: { token: idToken } }
        )
        .then(res => {
          this.orders = res.data;
          this.orders.map(order => {
            order.timestring = moment(order.timestamp).format("lll");
          });
          this.orders.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
          this.isLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.isLoading = false;
        });
    });
  },
  metaInfo() {
    return {
        title: 'จัดการออเดอร์',
    }
  }
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #FA8072;
}
</style>