import firebase from "firebase/compat/app";
import "firebase/compat/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCcTTcC9xeUeLnBZONLnTpG9V_g1cHm3g0",
  authDomain: "mathong-b6742.firebaseapp.com",
  databaseURL: "https://mathong-b6742.firebaseio.com",
  projectId: "mathong-b6742",
  storageBucket: "mathong-b6742.appspot.com",
  messagingSenderId: "471412690216",
  appId: "1:471412690216:web:7690fcc33b132c1d44141d",
  measurementId: "G-L2KTJC5C1Q"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default firebaseApp;
