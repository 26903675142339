<template>
  <div id="app">
    <div class="text-center" style="text-align: center; margin-top: 100px">
      <h1>ม้าทองการเกษตร ย้ายไปใช้ไลน์แอดแล้วนะ</h1>
      <h3>สามารถสั่งซื้อได้ที่ Line Official <span style="color: green">@mathong</span> ตั้งแต่วันนี้เป็นต้นไป</h3>
      <div><img src="https://qr-official.line.me/gs/M_rqv5061z_GW.png?oat_content=qr" /></div>
      <a href="https://lin.ee/9mr0y1p"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: "Tahoma", "Avenir", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100%;
  font-size: 1.3em;
}

button {
  font-family: "Tahoma", "Avenir", Helvetica, Arial, sans-serif !important;
}
</style>
