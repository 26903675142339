<template>
  <v-app id="inspire">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Login form</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field label="Login" name="login" prepend-icon="mdi-account" type="email" v-model="email" required />

                <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password" v-model="password" required />
              </v-form>
              <div>
                <p v-if="feedback" class="red--text">{{ feedback }}</p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="login">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import firebase from "@/firebase/init";

export default {
  name: "Login",
  methods: {
    login() {
      if (this.email && this.password) {
        this.feedback = null;

        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(cred => {
            this.$router.push({ name: "Admin" });
          })
          .catch(err => {
            // console.log(err);
            this.feedback = err.message;
          });
      } else {
        this.feedback = "You must enter email and password";
      }
    },
  },
  data() {
    return {
      email: null,
      password: null,
      feedback: null,
    };
  },
  metaInfo() {
    return {
      title: "เข้าสู่ระบบ",
    };
  },
};
</script>
