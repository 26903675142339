import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/Home";
import Order from "@/components/Order";
import Login from "@/components/Login";
import Admin from "@/components/Admin";
import firebase from "@/firebase/init";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/order/:id",
    name: "Order",
    component: Order
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    let user = firebase.auth().currentUser;
    if (user) next();
    else next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
